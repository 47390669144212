import React from 'react';
import { Container, Row, Col, Image, Button, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronDoubleRight } from 'react-bootstrap-icons';


function Home() {
  const navigate = useNavigate();
  return (
    <>
      <section id="hero" className="mb-5">
        <Container>
          <Row>
            <Col>
              <Image src="/assets/img/hero.jpeg" alt="East River Park Track" fluid style={{ objectFit: 'cover', objectPosition: 'center', maxHeight: '400px', width: '100%' }} />
              <div className='text-secondary text-center'>East River Park Track</div>
            </Col>
          </Row>
        </Container>
      </section>
      
            <section id="home" className="mb-5">
        <Container>
          <Alert variant='warning'>🚨 Crucial Community Board Vote on March 13: <Link to="https://news.lightthetrack.org/p/help-light-the-east-river-park-track-crucial-community-board-vote-next-week">Join us</Link> at the meeting on the LES to show your support.</Alert>

          <Row>
            <Col>
              <p>
<strong>The East River Park Track will soon close</strong> through at least 2026 and undergo a complete reconstruction as a part of the <a href='https://www.nyc.gov/site/escr/index.page'>East Side Coastal Resiliency (ESCR)</a> project. The <strong><Link to='https://www.nyc.gov/assets/escr/downloads/pdf/DDC-f-ESCR-pres-rev-final-given-to-PDC-2020-01-30.pdf#page=59' >designs</Link> for the new track do not include sports lighting</strong>. Now is the time for our community to organize and petition our city to incorporate lighting into this fully funded reconstruction project, ensuring the rebuilt track meets the needs of the community now and in the future.
              </p>
            </Col>
          </Row>
             <Row>
                <Col className='text-center'>
                    <Button className='m-2' variant="info" size='lg' onClick={() => navigate('/petition')}>🖊️ Sign the petition to light the track</Button>
                </Col>
          </Row>
        </Container>
      </section >

      <section id="about" className="mb-5">
        <Container>
          <Row>
            <Col>
              <h2>Why Lighting Matters</h2>
              <p><strong>🛡️ Safety:</strong> The track in its current and future planned form has inadequate lighting, which creates risks of personal harm and athletic injuries after dark. Sports lighting would provide the necessary visibility for safe use in the evening and would also serve as a deterrent to crime and vandalism.  A <Link to="https://www.nber.org/papers/w25798">study by the National Bureau of Economic Research
              </Link> found a 36% reduction in serious crimes in areas with improved lighting. <Link to="https://www.sciencedirect.com/science/article/pii/S0272494424001476?via%3Dihub#sec3">Another study</Link> found that brighter lighting in parks leads to park patrons feeling 81.7% more likely to report higher feelings of safety.</p>
                <p><strong >🌙 Increase Access:</strong> Many of New Yorker City's 8+ million residents rely on evening hours to exercise due to work or school schedules. Lighting ensures the track remains open and usable year-round.</p>
              <p><strong>📈 Maximize Public Investment:</strong> Adding lighting during the ESCR project reconstruction is far more cost-effective than retrofitting later. This ensures the track reaches its full potential as a safe, accessible, and well-used public space.</p>
              <p>
                The East River Park Track is one of only two regulation tracks open to the public in Manhattan and the only one located south of 135th Street (the other regulation track is located at Riverbank State Park on the Upper West Side). It is a vital community resource that deserves to be ready to meet the community’s needs for safe all-year-round use after its reconstruction.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col>
              <h2>A Missed Opportunity Without Lighting</h2>
              <div className='text-center mb-2'><Image src="/assets/img/mccarren park.jpg" alt="McCarren Park Track" fluid thumbnail style={{ maxHeight: '300px' }} />
                <div className='text-secondary'>McCarren Park Track in Williamsburg, Brooklyn
                </div>
              </div>
              <p>
                Brooklyn’s McCarren Park Track, equipped with sports lighting, thrives as a community hub, welcoming runners and other park patrons throughout the evening. Lower Manhattan deserves the same.
              </p>
              <p>
                By failing to include sports lighting in the East River Park Track plans, the city risks creating a facility that remains inaccessible for large portions of the year and day—wasting the potential of this multi-million-dollar investment.
              </p>
            </Col>
          </Row>
        </Container >
      </section>

      <section id="action" className="mb-5 bg-light py-5">
        <Container>
          <h2>Take Action</h2>
          <p>We are calling on the city to amend the ESCR project plans to include sports lighting for the East River Park Track.
          </p>
          <p><strong>Here’s how you can help:</strong></p>
          <p>🖊️ <strong>Sign The Petition</strong> Help us show city, state and federal officials that lighting the track matters to the community. <Button variant='secondary' onClick={() => navigate('/petition')}><ChevronDoubleRight /></Button></p>
          <p>✉️ <strong>Sign up for our mailing list</strong> Stay aware of the latest updates.  <Button variant='secondary' onClick={() => navigate('/subscribe')}><ChevronDoubleRight /></Button></p>
          <p>📣 <strong>Spread the word</strong> Share your support on social media using #LightTheTrack.</p>
        </Container >
      </section >

      <section id="about" className="mb-5">
        <Container>
          <Row>
            <Col>
              <h2>About Us</h2>
              <p>
                We are a coalition of runners and community advocates dedicated to ensuring East River Park Track is safe and accessible for all. We believe every New Yorker deserves access to well-lit, high-quality athletic facilities that promote health, safety, and community.
              </p>
              <p><strong>Jason Froimowitz</strong> is a software development professional, community organizer and runner. He also is a member of  Manhattan Community Board 6 and serves as the chair of its Transportation Committee.</p>
              <p><strong>Stephen Cox</strong> is a research scientist in New York and serves as a run club leader, track meet organizer, and race photographer with the Dashing Whippets.</p>
               <p><strong>Daniel Hui</strong> is runner and resident of the east side who has developed urban master planning strategies for rapidly growing cities around the world.</p>
              <p><strong>Angela Petrone</strong> is a resident of the East Village with over a decade of risk management and marathon experience.</p>
             </Col>
          </Row>
        </Container>
      </section>

      <section id="contact" className="mb-5 bg-dark text-white py-5" >
        <Container>
          <Row>
            <Col>
              <h2>Contact Us</h2>
              <p>Have questions or want to get involved? Reach out to us!</p>
              <p><strong>Email:</strong> <a href="mailto:info@lightthetrack.org" className='text-decoration-none text-white'>info@lightthetrack.org</a></p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Home;
