import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container, Navbar, Nav, Col, Row } from 'react-bootstrap';
import Home from './pages/Home.js';
import Petition from './pages/Petition.js';
import PrivacyPolicy from './pages/PrivacyPolicy.js'
import Subscribe from './pages/Subscribe.js';
import Credits from './pages/Credits.js';

function App() {
  return (
    <Router>
      <Navbar bg="dark" variant="dark" className="">
        <Container>
          <a href="/" className="text-decoration-none">
            <Row>
              <Col className="d-flex justify-content-center align-items-center">
                <img
                  src="./assets/img/light.png" height="36px" alt="logo" />
              </Col>
              <Col className=''>
                <Row>
                  <Navbar.Brand>Light The Track</Navbar.Brand>
                </Row>
                <Row>
                  <div className="text-secondary" style={{ fontSize: '0.875rem' }}>at East River Park</div>
                </Row>
              </Col>
            </Row>
          </a>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/petition">Petition</Nav.Link>
              <Nav.Link href="https://news.lightthetrack.org">Updates</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar >

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/petition" element={<Petition />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/credits" element={<Credits />} />
      </Routes>
      <Container className="text-center mb-2 mt-2">
        <div className="text-secondary"><a className="text-secondary text-decoration-none" href='/privacy'>Privacy Policy</a> | <a className="text-secondary text-decoration-none" href='/credits'>Credits</a></div>
      </Container>
    </Router >
  );
}

export default App;